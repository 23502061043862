<template>
  <div
    id="toast"
    @click="clickCloseToast"
    class="fixed py-2 px-6 left-1/2 transform -translate-x-1/2 text-white rounded-full text-center transition-all duration-500 cursor-pointer z-50"
    :class="[genClassToast, color]"
  >

    <div class="flex items-center justify-center space-x-2">
      <svg v-if="toastData?.type == 'success'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
      </svg>
      <svg v-if="toastData?.type == 'error'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
      <svg v-if="toastData?.type == 'warning'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
      </svg>
      <svg v-if="toastData?.type == 'info'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <span class="text-base">{{ toastData.text }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAppStateStore } from '~/stores/appState'

const appStateStore = useAppStateStore()

const toastData = computed(() => appStateStore.toast)
const genClassToast = computed(() => {
  const { isShow } = appStateStore.toast
  return isShow ? 'opacity-1 top-4' : 'opacity-0 -top-10'
})

const color = computed(() => {
  const colors:any = {
    success: 'bg-green-500',
    error: "bg-red-500",
    info: 'bg-blue-200',
    warning: 'bg-orange-500'
  }

  return colors[toastData?.value?.type]
})

const clickCloseToast = () => {
  appStateStore.closeToast()
}
</script>


<style>

</style>